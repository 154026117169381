<template>
  <footer id="information">
    <div class="footer-content">
    <div class="part">
      <h3>Contactez nous !</h3>
      <ul class="footer-list">
        <li>
          <h4>Mail</h4>
          <p><a href="mailto:adress-mail@mail.com">info@atom-controles.ch</a></p>
        </li>
        <li>
          <h4>Téléphone</h4>
          <p>077 / 436 11 87</p>
        </li>
        <li>
          <h4>Courrier</h4>
          <p>Route de Saint-Légier 60<br>1800 Vevey CH</p>
        </li>
      </ul>
    </div>
    <div class="hr" />
    <div class="part">
      <h3>ATOM Contrôles</h3>
      <ul class="footer-list">
        <li>
          <h4>Entreprise</h4>
          <p>Baptista Flavio</p>
          <p>Conseiller en sécurité électrique</p>
        </li>
      </ul>
    </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss" src="./footer-component.scss"></style>
