<template>
  <div>
    <img src="@/assets/prestations/conduite_eau.webp" alt="Conduite d'eau" class="illustration" />
    <p>
      Dans les bâtiments anciens, qui sont encore reliés au réseau d’alimentation par un branchement métallique, la mise à terre de l’installation domestique est souvent raccordée à celui-ci.
    </p>
    <p>
      Lors du remplacement ou de la réparation de branchements d’immeuble métalliques, la mise à terre des installations électriques est souvent interrompue.
    </p>
    <p>
      Toute interruption de la mise à terre entraîne un risque d’électrocution important pour les personnes et animaux. Dans les bâtiments disposant d’une installation de protection contre la foudre, l’effet de celle-ci peut être moindre voire nul.
    </p>
    <p>
      Nous vous proposons une formation théorique et pratique adaptée aux services industriels de l’eau afin de procéder et d’être autonomes pour réaliser les nouvelles mises à terre.
    </p>
    <h5>Cours mis en place</h5>
    <ol>
      <li>Service Industriel de la ville de Pully</li>
      <li>Service de l’eau de la ville de Lausanne</li>
    </ol>
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped lang="scss" src="../../prestation/sections/section-component.scss">
</style>
