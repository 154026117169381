import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/home/home-view.vue'
import SocieteView from '@/views/societe/societe-view.vue'
import PrestationView from '@/views/prestation/prestation-view.vue'
import FormationsView from '@/views/formations/formations-view.vue'
import ContactView from '@/views/contact/contact-view.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/societe',
    name: 'societe',
    component: SocieteView
  },
  {
    path: '/prestations',
    name: 'prestations',
    component: PrestationView
  },
  {
    path: '/formations',
    name: 'formations',
    component: FormationsView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404/404-view.vue')
  },
  {
    path: '/:pathmatch(.*)',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    }
  }
})

export default router
