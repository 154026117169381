<template>
  <div>
    <img src="@/assets/prestations/tableau_epfl.webp" alt="Panneaux électrique" class="illustration" />
    <h3>Qu'est-ce c'est ?</h3>
    <p>
      Le contrôle périodique est une expertise de l’installation électrique, afin d’assurer la sécurité des personnes et des choses ainsi que les installations soient réalisées selon les normes en vigueur.
    </p>
    <h3>Devoir du propriétaire</h3>
    <p>
      Le propriétaire ou représentant mandaté veille à ce que l’installation électrique réponde en tout temps aux exigences fondamentales concernant la sécurité et la lutte contre les perturbations. Suite au contrôle périodique, il doit présenter un rapport de sécurité (OIBT art. 5, al.1)
    </p>
    <h3>Procédure du contrôle </h3>
    <ol>
      <li>
        Au moins six mois avant la fin de la période de contrôle, le distributeur d’électricité de votre région ou, dans certains cas, l’Inspection fédérale des installations à courant fort ESTI, Invite par écrit le propriétaire de présenter le rapport de sécurité avant la fin de la période de contrôle. Des périodes de contrôle de 1, 3, 5, 10 ou 20 ans sont prévues selon le type d’installation. Les installations électriques soumises au contrôle tous les 10 ou 20 ans doivent en outre être contrôlées après tout changement de propriétaire, si le dernier contrôle effectué date de cinq ans.
      </li>
      <li>
        Le propriétaire mandate un organe de contrôle indépendant pour exécuter le contrôle périodique de son bien immobilier.
      </li>
      <li>
        A la fin du contrôle, si des défauts ont été constatés, l’organe de contrôle indépendant rédige un rapport de défauts qui sera transmis au propriétaire. Si aucun défaut n’a été constaté l’organe de contrôle délivre le rapport de sécurité au propriétaire et une copie au distributeur d’électricité, ce qui relance la périodicité de contrôle jusqu’à la prochaine échéance.
      </li>
      <li>
        Suite aux défauts mentionnés sur le rapport, le propriétaire mandate une société d’électricité possédant une autorisation d’installer afin de supprimer les défauts.
      </li>
      <li>
        Lorsque les défauts ont été supprimés, l’organe de contrôle délivre le rapport de sécurité au propriétaire et une copie au distributeur d’électricité. Cette étape relance la périodicité de contrôle jusqu’à la prochaine échéance.
      </li>
    </ol>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" src="./section-component.scss">
</style>
