<template>
  <div>
    <img src="@/assets/prestations/tableau_electrique.webp" alt="Contrôle final" class="illustration" />
    <h3>Qu'est-ce c'est ?</h3>
    <p>
      Le contrôle final est une expertise de l’installation électrique, afin d’assurer la sécurité des personnes et des choses ainsi que les installations soient réalisées selon les normes en vigueur.
    </p>
    <p>
      Conformément à l’ordonnance sur les installations électriques à basse tension (OIBT ; RS 734.27), les installations électriques neuves, modifiées et remises en état doivent faire l’objet d’un contrôle final (cf. art. 24, al. 2 OIBT)
    </p>
    <p>
      Le contrôle final est à exécuter par l’installateur électricien en charge des travaux, l’installateur électricien peut également mandater un organe de contrôle indépendant pour réaliser ce contrôle.
    </p>
    <p>
      À la suite du contrôle, l’électricien transmets un rapport de sécurité au propriétaire ainsi qu’une copie au distributeur d’énergie.
    </p>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" src="./section-component.scss">
</style>
