<template>
  <section id="societe">
    <div class="card">
      <h3>Société</h3>
      <p>ATOM Contrôles, une entreprise dynamique et consciencieuse.</p>
    </div>
    <div class="card">
      <h3>Philosophie</h3>
      <p>
        Du fait de notre domaine d'activité, nous veillons à être toujours informés sur les dernières normes de sécurité en vigueur. Afin de vous faire bénéficier d'un service de qualité optimale. A l’écoute de nos clients et partenaires, nous mettons tout en œuvre pour fournir des conseils avisés et techniques lors de suivi de chantiers, planifications et accompagnement de futurs projets.
      </p>
    </div>
    <div class="card">
      <h3>Objectifs</h3>
      <div>
        <ul>
          <li>Notre principal objectif est d'assurer votre sécurité électrique, en effectuant des contrôles de votre installation, afin de détecter tous éventuels dangers ou risques d'accidents et incendies.</li>
          <li>Nous intervenons à la demande de propriétaires de bâtiments, gérances, communes ainsi que d'installateurs électriciens pour effectuer un contrôle de sécurité selon les exigences fixées dans l'ordonnance sur les installations électriques à basse tension (OIBT).</li>
        </ul>
      </div>
    </div>
    <div class="card">
      <h3>Rayon d'action</h3>
      <div>
        <ul>
          <li>
            Notre équipe se tient à disposition dans toute la Romandie.
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
</script>

<style scoped lang="scss">
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .card {
    max-width: 980px;
    width: 100%;
    box-sizing: border-box;
    padding: 3rem;
    text-align: left;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin: 10px;
    border-radius: 20px;
  }
  @media screen and (max-width: 768px) {
    .card {
      padding: 1rem;
    }
  }
  ul {
    padding-left: 1rem;
  }
  li {
    list-style: disc;
  }
</style>
