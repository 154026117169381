<template>
  <div>
    <img src="@/assets/prestations/mesure.webp" alt="Mesure" class="illustration" />
    <p>
      Vous avez des soupçons sur votre installation électrique ?
    </p>
    <p>
      Une expertise permet d’éclaircir les éventuels défauts et dangers, afin de prévenir d’un éventuel accident. Un rapport de contrôle détaillé des mesures de correction à entreprendre afin de garantir la protection des personnes et des biens. Attention, il est obligatoire que contrôle soit exécuté par un organe de contrôle indépendant de celui qui à réaliser l’installation.
    </p>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" src="./section-component.scss">
</style>
