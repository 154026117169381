<template>
  <div class="sign">
    <p>© Copyright, website by <a href="https://slordef-webmaster.ch">WebDev M. Slordef Valenzano</a></p>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss" src="./sign-component.scss"></style>
