<template>
  <div>
    <img src="@/assets/prestations/vehicule_electrique.webp" alt="Véhicule électrique" class="illustration" />
    <p>
      Complexe et en constante évolution, le marché des énergies renouvelables demande de nouvelles compétences en permanence. C’est pourquoi, nous suivons attentivement ces dernières afin d’acquérir le nécessaire pour les contrôles de bornes de recharge pour véhicules électriques, professionnels et responsable.
    </p>
    <p>
      Nous possédons les compétences pour exécuter les contrôles de borne de recharge pour véhicules électriques finaux, réception et périodiques de vos installations.
    </p>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" src="./section-component.scss">
</style>
