<template>
  <div>
    <img src="@/assets/prestations/paneaux_electrique.webp" alt="Panneaux électrique" class="illustration" />
    <h3>Qu'est-ce c'est ?</h3>
    <p>
      Le contrôle de réception est une expertise de l’installation électrique, afin d’assurer la sécurité des personnes et des choses ainsi que les installations soient réalisées selon les normes en vigueur.
    </p>
    <p>
      Le contrôle de réception doit être effectué par un organe indépendant dans les 6 mois à la suite de la mise en service de la nouvelle installation.
    </p>
    <p>
      Le contrôle de réception doit être effectué par un organe indépendant dans les 6 mois à la suite de la mise en service de la nouvelle installation.
    </p>
    <p>
      À la suite du contrôle, l’organe de contrôle transmets un rapport de sécurité au propriétaire ainsi qu’une copie au distributeur d’énergie.
    </p>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" src="./section-component.scss">
</style>
