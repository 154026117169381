<template>
  <div>
    <img src="@/assets/prestations/formation.webp" alt="Conduite d'eau" class="illustration" />
    <p>
      Le stage de formation a été élaboré avec la collaboration de l’ASCE.
    </p>
    <p>
      Il sert de lien entre les connaissances de spécialiste acquises sur le contrôle d’installations électriques et la pratique.
    </p>
    <p>Les candidats apprennent au quotidien dans le cadre de leur préparation à l’examen final les déroulements et la nécessité des contrôles électriques finaux, de réception et périodiques.</p>
    <p>
      Les candidats reçoivent ainsi un aperçu des compétences qui sont indispensables à l’activité de leur future activité professionnelle qui est exigeante et responsable.
    </p>
    <br>
    <br>
    <p>
      Ci-joint le lien du rapport de stage selon EIT Swiss : <a href="https://www.vsek.ch/download/download/Praktikumsbericht_VSEK_FRA_V3_06022019.pdf">Lien vers le document</a>
    </p>
  </div>
</template>

<script lang="ts" setup>
</script>

<style scoped lang="scss" src="../../prestation/sections/section-component.scss">
</style>
