<template>
  <div>
    <img src="@/assets/prestations/photovoltaïque2.webp" alt="photovoltaique" class="illustration" />
    <h3>Qu'est-ce c'est ?</h3>
    <p>
      Complexe et en constante évolution, le marché des énergies renouvelables demande de nouvelles compétences en permanence. C’est pourquoi, nous suivons attentivement ces dernières afin d’acquérir le nécessaire pour les contrôles photovoltaïques, professionnels et responsable.
    </p>
    <p>
      Nous possédons les compétences pour exécuter les contrôles photovoltaïques finaux, réception et périodiques de vos installations.
    </p>
    <p>
      Reconnu par <a href="https://pronovo.ch/fr/">PRONOVO</a>, nous pouvons certifier votre installation photovoltaïque afin d’obtenir votre subvention.
    </p>
  </div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" src="./section-component.scss">
</style>
