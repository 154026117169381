<template>
  <section class="what">
<!--    <div class="logo">-->
<!--      <img src="@/assets/logo.svg" alt="ATOM Contrôles">-->
<!--    </div>-->
<!--    <div class="explanation">-->
<!--      <h1 class="title">ATOM Contrôles</h1>-->
<!--      <p class="slogan">Vôtre sécurité, notre priorité.</p>-->
<!--    </div>-->
    <h1>Vôtre sécurité, notre priorité.</h1>
  </section>
</template>

<script lang="ts" setup>
</script>

<style scoped lang="scss">
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 50%;
}

.logo img {
  width: 100%;
  max-width: 980px;
  padding: 40px;
  height: auto;
}

.explanation {
  flex: 1 1 50%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  font-size: 1.5rem;
}

section.what {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 120px auto 80px auto;
}
.title {
  margin-top: 120px;
  margin-bottom: 0;
}
.slogan {
  margin-top: 0;
}
h1 {
  font-size: 3rem;
  font-weight: bolder;
  margin: 0;
  text-align: center;
}
@media screen and (max-width: 768px) {
  section.what {
    box-sizing: border-box;
  }
  .explanation {
    font-size: 1rem;
  }
  h1 {
    font-size: 2rem;
  }
}
</style>
